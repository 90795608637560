<template>
     <div class="industry body">
          <div class="container headcarousel">
               <div>
                    <div class="columns reverse-columns">
                         <div class="column ">
                              <div class="image" data-aos="fade-right" data-aos-duration="1000">
                                   <!-- <img :src="require(`@/assets/images/industry.png`)" alt="" /> -->

                                   <div
                                        class="square img"
                                        :style="{ 'background-image': `url(${require('@/assets/images/industries/banking.jpg')})` }"
                                   ></div>
                              </div>
                         </div>
                         <div class="column">
                              <div class="text" data-aos="fade-left" data-aos-duration="1000">
                                   <h1>Finance <span class="uppertext">03</span></h1>

                                   <div class="mt-30">
                                        <p>
                                             The CTL promise of safe, dependable and steadfast systems that help your customers choose you.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div class="projects">
               <div class="container">
                    <div class="section-title">Finance & Banking Projects</div>

                    <div class="columns" style="margin-bottom:50px;">
                         <div class="column">
                              <div class="section-header" style="margin-bottom:0;">
                                   Secure & Efficient technology that promotes trust.
                              </div>
                         </div>

                         <div class=" hoopernavigation">
                              <button class="prev" @click="hooperPrev()" :class="{ visible: hooper.isPrevBtnVisible }">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                        <g id="Group_415" data-name="Group 415" transform="translate(1115.74 145.803) rotate(180)">
                                             <line
                                                  id="Line_40"
                                                  data-name="Line 40"
                                                  x2="42"
                                                  transform="translate(1073 136)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                             <path
                                                  id="Path_21"
                                                  data-name="Path 21"
                                                  d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                  transform="translate(2)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                        </g>
                                   </svg>
                              </button>
                              <button class="next" @click="hooperNext()" :class="{ visible: hooper.isNextBtnVisible }">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                        <g id="Group_415" data-name="Group 415" transform="translate(-1073 -126.299)">
                                             <line
                                                  id="Line_40"
                                                  data-name="Line 40"
                                                  x2="42"
                                                  transform="translate(1073 136)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                             <path
                                                  id="Path_21"
                                                  data-name="Path 21"
                                                  d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                  transform="translate(2)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                        </g>
                                   </svg>
                              </button>
                         </div>
                    </div>
               </div>
               <div class="overflow-carousel-container">
                    <div class="overflow-carousel">
                         <hooper :settings="hooper_settings" class="slider" ref="hooper" @slide="hooperSlide">
                              <slide v-for="(item, index) in projects" :key="index" class="">
                                   <div class="slide-container">
                                        <div class="image-container">
                                             <!-- <img :src="require(`@/assets/images/${item.image}`)" /> -->

                                             <div
                                                  class="rect img"
                                                  v-if="item.image"
                                                  :style="{ 'background-image': `url(${require('@/assets/images/projects/' + item.image)})` }"
                                             ></div>

                                             <div
                                                  class="rect img"
                                                  v-else
                                                  :style="{ 'background-image': `url(${require('@/assets/images/placeholder.png')})` }"
                                             ></div>
                                        </div>
                                        <div class="mt-30 line-left  " style="padding-left:20px;min-height:91px;">
                                             <div class="date">{{ item.location }}</div>
                                             <p class="mt-10 large " v-html="item.name"></p>
                                        </div>
                                   </div>
                              </slide>
                         </hooper>
                    </div>
               </div>
          </div>

          <div class="content">
               <div class="container">
                    <div class="  section-title">Finance Services</div>
                    <div class="  section-header">
                         Secure & Efficient technology that promotes trust.
                    </div>

                    <div class="columns">
                         <div class="column ">
                              <div class="line-top ">
                                   <!-- <div class="section-title ">
                                        Benefits of ITeS System
                                   </div> -->
                                   <p>
                                        Scalability is a very significant criteria for most of the BFSI institutions as their operations are spread
                                        out geographically. The software system can be easily scaled up without much impact on the network. Hence, it
                                        is crucial that the network infrastructure like structured cabling, switching and routing are designed
                                        considering the future expansion. This is important as software demands more bandwidth in future and banks
                                        should be able to scale up their system easily and efficiently to continuously cater to the demands of their
                                        customers. However, many of the legacy IT infrastructure systems were not designed to be scalable; so
                                        financial firms often have to incur additional costs to build custom and complex workarounds to scale up their
                                        existing IT operations. more digitally-enabled banks, supported by cloud.
                                   </p>
                              </div>
                         </div>
                         <div class="column ">
                              <div class="line-top">
                                   <!-- <div class="section-title ">
                                        Specific Services
                                   </div> -->
                                   <p>
                                        Cloud enables financial institutions to be more agile, scalable, and flexible with its ability to operate in a
                                        dynamic, contact-less environment. Migrating traditional data centers to a cloud server helps in increasing
                                        security, boosting data-handling capacity, and providing access to new delivery channels.
                                   </p>
                              </div>
                              <div class="line-top pt-6 mt-6">
                                   <div class="section-title ">
                                        Cloud solution for the BFSI
                                   </div>
                                   <p>
                                        A cloud based infrastructure can talk to multiple servers simultaneously. It is more dynamic and real time.
                                   </p>

                                   <p>
                                        Clients need not to build the Data Center and DR center to manage their applications. This reduces capital
                                        investment and recurring expenses.
                                   </p>
                                   <p>
                                        Moving from legacy systems to a more efficient, cloud-based setup eliminates the overheads involved in
                                        managing IT staff , and purchasing high end Hardwares, AMC, etc.
                                   </p>
                                   <p>
                                        For this reason, many small banks are moving to a cloud SAS model solution rather than setting up their own IT
                                        infra.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import { Hooper, Slide } from "hooper";
     import "hooper/dist/hooper.css";

     export default {
          data() {
               return {
                    hooper: {
                         slideNo: 0,
                         isPrevBtnVisible: false,
                         isNextBtnVisible: true,
                    },

                    hooper_settings: {
                         wheelControl: false,
                         centerMode: false,
                         trimWhiteSpace: true,
                         breakpoints: {
                              800: {
                                   itemsToShow: 1,
                                   itemsToSlide: 1,
                              },
                              1000: {
                                   itemsToShow: 3.6,
                                   itemsToSlide: 1,
                              },
                         },
                    },

                    sliderOptions: {},
                    selected: 0,

                    projects: [
                         {
                              name: "Federal Bank Cooperate office and branches",
                              location: "Pan India (800 branches)",
                              text: "ICT Solutions <br> Structured Cabling <br> ITeS Solution",
                              image: "finance/federal_bank/1.jpg",
                         },

                         {
                              name: "ESAF",
                              location: "Kerala",
                              text: "ICT Solution <br> ITeS Solutions <br> IT Active <br>  Structured Cabling",
                              image: "finance/ESAF/1.jpg",
                         },
                         {
                              name: "Dhanalakshmi Bank ",
                              location: "Pan India ( 30 branches)",
                              text:
                                   "ICT Solutions <br> Entry control systems <br> IP Surveillance <br> Access control Systems <br> Structured Cabling ",
                              image: "finance/DHANAKSHMI/1.jpg",
                         },
                         {
                              name: "South Indian Bank Corporate office and branches ",
                              location: "Pan India (700 branches)",
                              text:
                                   "ICT Solution <br> IP Surveillance <br> ITeS  Solution <br> Data Centre <br> Intelligent solution.                                          ",
                              image: "finance/South_indian_bank/1.jpg",
                         },
                         {
                              name: "KSFE",
                              location: "India",
                              text: "ICT Solutions <br> Structured Cabling and ITeS Solution",
                              // image: 'finance//',
                         },
                         {
                              name: "Axis Bank",
                              text: "ICT Solutions <br> Structured Cabling and ITeS Solution",
                              location: "India",
                              // image: 'finance/Axis bank/',
                         },
                         {
                              name: "canara bank",
                              location: "India",
                              text: "ICT Solutions <br> Structured Cabling and ITeS Solution",
                              image: false,
                         },
                    ],
               };
          },
          components: {
               Hooper,
               Slide,
          },
          methods: {
               hooperSlide(data) {
                    this.hooper.slideNo = data.currentSlide;

                    if (this.hooper.slideNo > 0) {
                         this.hooper.isPrevBtnVisible = true;
                    } else {
                         this.hooper.isPrevBtnVisible = false;
                    }

                    if (window.innerWidth < 1000) {
                         if (this.hooper.slideNo == this.projects.length - 1) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    } else {
                         if (this.hooper.slideNo > 3.3) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    }
               },
               hooperPrev() {
                    this.$refs.hooper.slidePrev();
               },
               hooperNext() {
                    this.$refs.hooper.slideNext();
               },
          },

          mounted() {},
     };
</script>

<style lang="scss" scoped></style>
